<template>
  <div class="component-upload-image">
    <el-upload
    :action="uploadImgUrl"
    list-type="picture-card"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :on-success="handleSuccess"
    :before-upload="beforeUpload"
    :http-request="uploadRequest"
    accept=".jpeg,.jpg,.png,.gif" 
    :file-list="fileList"
  >
    <el-icon><Plus /></el-icon>
  </el-upload>
  <el-dialog v-model="dialogVisible" size="tiny">
    <img w-full :src="dialogImageUrl" alt="Preview Image" class="preview-image" />
  </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    fileListTemp:{
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      number: 0,
      dialogImageUrl: [],
      uploadList:[],
      dialogVisible: false,
      hideUpload: false,
      baseUrl:'https://file.listensoft.net',
      uploadImgUrl:  "https://public.listensoft.net/api/uploadImg", // 上传的图片服务器地址
    };
  },
  watch: {
  },
  computed: {
  
  },
  created(){
    if(this.fileListTemp){
      const list = Array.isArray(this.fileListTemp) ? this.fileListTemp : this.value.split(',');
          // 然后将数组转为对象数组
          this.fileList = list.map(item => {
            if (typeof item === "string") {
              if (item.indexOf(this.baseUrl) === -1) {
                  item = { name: this.baseUrl + item, url:  item };
              } else {
                  item = { name: item, url: item };
              }
            }
            return item;
          });
        console.log(this.fileList);
    }else{
          this.fileList = [];
          return [];
        }
  },
  methods: {
    // 查看图片
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 删除
    handleRemove(file, fileList) {
      console.log(this.fileList);
      // let files = file.url
      // console.log(files);
      // const findex = this.fileList.map(f => f.url).indexOf(files);
      // if(findex > -1) {
      //   this.fileList.splice(findex, 1);
      //   let temp = []
      //   this.fileList.forEach(item=>{
      //     temp.push(item.url)
      //   })
      //   console.log(this.fileList);
      //   this.$emit("del", temp);
      // }
      let temp = []
      this.fileList.forEach(item=>{
        temp.push(item.url)
      })
      this.$emit("del", temp);
      console.log(this.fileList);
    },
    handleSuccess(response, file, fileList) {
      file.url = 'https://file.listensoft.net' + response.data.url;
      this.uploadList.push({name:file.url,url:file.url});
      if (this.uploadList.length === this.number) {
        let temp = []
        this.fileList = this.fileList.concat(this.uploadList);
        this.fileList.forEach(val => {
          temp.push(val.url)
        });
        this.$emit("updatet", temp);
        this.uploadList = [];
        this.number = 0;
        this.$qzfMessage("图片上传成功");
      }
      console.log(this.fileList);

    },
    beforeUpload(file) {
      let fileTypeTemp = ["png", "jpg", "jpeg"]
      let fileType =file.type.split('/')
      const isJPG = fileTypeTemp.includes(fileType[1])
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      this.number++;
      return isJPG && isLt2M;
    },
    uploadRequest(option) {
      const files = option.file;
      const formData = new FormData();
      formData.append('upFile', files, files.name);
      axios.post(this.uploadImgUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        option.onSuccess(response.data);
      })
      .catch((error) => {
        option.onError(error);
        this.$message.error('上传出现错误');
        console.error(error);
      });
    },
    // 对象转成指定字符串分隔
    // listToString(list, separator) {
    //   let strs = "";
    //   separator = separator || ",";
    //   for (let i in list) {
    //     strs += list[i].url.replace(this.baseUrl, "") + separator;
    //   }
    //   return strs != '' ? strs.substr(0, strs.length - 1) : '';
    // }

  }
};
</script>
<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
::v-deep.hide .el-upload--picture-card {
    display: none;
}
// 去掉动画效果
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
    transition: all 0s;
}

::v-deep .el-list-enter, .el-list-leave-active {
    opacity: 0;
    transform: translateY(0);
}
:deep(.el-upload--picture-card){
  --el-upload-picture-card-size: 80px!important;
}
/* 确保图片自适应对话框大小 */  
.preview-image {  
  max-width: 100%;  
  height: auto;  
  display: block;  
  margin: 0 auto;  
}  
 
</style>
