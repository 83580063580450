<template>
  <div style="position: relative;">
    <div class="export_btn">
      <el-button size="small" type="success" @click="daochu()" :disabled="!$buttonStatus('dzsj_sksb_dc')">
        <el-icon><Folder /></el-icon><span  > 导出</span>
      </el-button>
    </div>
   <!-- todo -->
   <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="税款申报" name="税款申报"></el-tab-pane>
      <el-tab-pane label="零申报" name="零申报"></el-tab-pane>
    <div class="top_btns">
      <div class="left_box">
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
        <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 160px;margin-left:5px" class="filter-item" size="small" @keyup.enter="getList" clearable/>
        <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 10px;width:660px" label-width="120px" :inline="true" size="small">
            <el-form-item label="税局："> 
              <selectcity v-model:citys="listQuery.districtCode" style="width:140px;margin: 0;"></selectcity>
            </el-form-item>
            <el-form-item label="选择人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:140px;margin: 0;"></selectuser>
            </el-form-item>
            <el-form-item label="纳税人类型：">          
                <selecttaxtype v-model:type="listQuery.type" style="width:140px;margin: 0;"></selecttaxtype>
              </el-form-item>
              <el-form-item label="报税手机号：">          
                <el-input v-model.trim="listQuery.tel" size="small" style="width:140px;" placeholder="请输入报税手机号"></el-input>
              </el-form-item>
              <el-form-item label="授权状态 ：">   
                <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:140px;margin: 0;"></selectAccreditStatus>
              </el-form-item>
              <el-form-item label="公司类型："> 
                <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型"  filterable clearable style="width:140px;margin: 0;">
                  <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="税款确认状态：" v-if="orgId == 3576">
                <select-common
                  placeholder="请选择确认状态"
                  v-model:modelValue="listQuery.ackStatus"
                  :options="confirmOption"
                  class="w-140 ml-mr-0"
                />
              </el-form-item>
              <el-form-item label="税款超时待确认：" v-if="orgId == 3576">
              <el-input v-model="listQuery.overTimeSt" style="width: 160px;"
                @change="(e) => { listQuery.overTimeSt = e ? Number(e) : null }"></el-input>
                -
              <el-input v-model="listQuery.overTimeEd" style="width:160px"
                @change="(e) => { listQuery.overTimeEd = e ? Number(e) : null }"></el-input>
            </el-form-item>
            <el-form-item label="扣款结果：">
              <selectBussinessKkStatus v-model:allStatus="listQuery.allStatus"></selectBussinessKkStatus>
            </el-form-item>
            <el-form-item label="金额区间：">
              <el-input placeholder="金额起" v-model="listQuery.beginMoney" style="width: 100px;" size="small" />-
              <el-input placeholder="金额止" v-model="listQuery.endMoney" style="width: 100px;" size="small" />
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <el-button :disabled="!$buttonStatus('dzsj_sksb_jf')" size="small" type="warning" @click="declaration()" :loading="kkLoading">
          <el-icon><Money /></el-icon><span  > 批量缴款</span>
        </el-button>
        <!-- <el-button :disabled="!$buttonStatus('dzsj_sksb_cxjkrw')" size="small" type="danger" @click="cancelTask()" :loading="cxLoading">
          <el-icon><List /></el-icon><span  > 撤销任务</span>
        </el-button> -->
      </div>
    </div>
    <div class="loudou">
      <el-table stripe :data="list" :height="contentStyleObj" border @select="handleSelectionChange" @filter-change="tableFilterChange" class="date-icon" v-loading="loading" @sort-change="sortChange" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column label="编号" align="center" prop="sort" width="80" fixed="left" sortable="custom">
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column align="left" prop="name" fixed label="公司名称" min-width="240">
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId"></TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column  prop="district" label="税局" width="65">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district)  }}</span>  
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="申报税种" width="250"
        :filters="this.categories">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p style="text-align: left;">{{$tableNameFilter(item.tableName)}}</p>
            </div>
          </template>
        </el-table-column>
<!-- 申报 -->
        <el-table-column align="left" prop="districtName" label="申报结果" min-width="170">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
                <div class="item_icon">
                  <p>
                    <i :class="$batchSendIconSb(item.taskStatusSb, item.bussinessStatusSb)"></i>
                    <el-tooltip class="item" effect="dark" :disabled="!item.businessLogSb" placement="top-start">
                      <template #content>
                        <div v-html="item.businessLogSb"></div>
                      </template>
                      <div style="display: inline-block;">
                        <span :class="$batchSendWordSb(item.taskStatusSb, item.bussinessStatusSb)">{{$batchSendSb(item.taskStatusSb,item.bussinessStatusSb)}}</span><i class="iconfont icon-wenhao" v-if="item.bussinessStatusSb == 3 && item.businessLogSb" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                        <!-- <el-button size="small" :class="$batchSendWordSb(item.taskStatusSb, item.bussinessStatusSb)" style="border: 0;font-size: 14px;">{{$batchSendSb(item.taskStatusSb,item.bussinessStatusSb)}}</el-button><i class="iconfont icon-wenhao" v-if="item.bussinessStatusSb == 3 && item.businessLogSb" style="color:#f56c6c;margin-left: 5px;"></i> -->
                      </div>
                    </el-tooltip>
                    <span v-if="item.imageSb" class="demo-image__preview" style="margin-left: 4px;">
                      <i class="iconfont icon-picture" style="color:red;font-size:13px;" v-if="item.bussinessStatusSb == 3" @click="open(item, index,scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#67c23a;font-size:13px;" v-if="item.bussinessStatusSb == 2 ||item.bussinessStatusSb == 6 ||item.bussinessStatusSb == 20 || item.bussinessStatusSb == 21 || item.bussinessStatusSb == 22" @click="open(item, index,scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#39b0d2;font-size:13px;" v-if="item.bussinessStatusSb == 4 || item.bussinessStatusSb == 5" @click="open(item, index,scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#e6a23c;font-size:13px;" v-if="item.bussinessStatusSb == 23" @click="open(item, index,scope.$index)"></i>
                    </span>
                  </p>
                </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="税款确认状态" width="150" v-if="orgId == 3576">
          <template #default="scope">
            <div class="item_icon">
              <i :class="$homeConfirmIcon(scope.row.djqr_tax_status)"></i>
              <span>{{ $homeTaxStatus(scope.row.djqr_tax_status) }}</span>
              <n-badge :value="$filterHomeBadge(scope.row?.jzTime)" show-zero :max="9"
                :color="$filterHomeBadge(scope.row?.jzTime, 'color')"
                v-if="scope.row?.djqr_tax_status == 4 && !(scope.row?.jzTime).match('0001')">
              </n-badge>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="应补（退）税额" min-width="130">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <p v-if="item.tax">{{item.tax}}</p>
              <p v-else>{{0}}</p>
            </div>
          </template>
        </el-table-column>
        
        <!-- 扣款 -->
        <el-table-column align="left" prop="districtName" label="扣款结果" min-width="210">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <div class="item_icon">
                <p>
                  <span v-if="item.image" class="demo-image__preview">
                    <i class="iconfont icon-picture" style="color:red;font-size:13px;" v-if="item.bussinessStatus == 3" @click="openKK(item, index,scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#67c23a;font-size:13px;" v-if="item.bussinessStatus == 2 ||item.bussinessStatus == 13" @click="openKK(item, index,scope.$index)"></i>
                  </span>
                  <i v-else :class="$batchSendIconSb(item.taskStatus, item.bussinessStatus)"></i>
                  <el-tooltip class="item" effect="dark" :disabled="!item.bussinessLog || item.bussinessStatus == '2' || item.bussinessStatus == '13'" placement="top-start">
                    <template #content>
                      <div v-html="item.bussinessLog"></div>
                    </template>
                    <span :class="$batchSendWordKk(item.taskStatus,item.bussinessStatus)">{{$batchSendKk(item.taskStatus,item.bussinessStatus)}}  <i class="iconfont icon-wenhao" style="color:red" v-if="item.bussinessStatus !=2 && item.bussinessStatus !=13 &&item.bussinessLog">
                    </i></span>
                  </el-tooltip>
                  <!-- {{item.endAt}} -->
                  <span style="color: #67c23a;">金额：</span>
                  <span v-if="item.amountPaid">{{item.amountPaid}} 
                    <el-tooltip class="item" effect="dark" placement="top" v-if="item.bussinessStatus == 2 || item.bussinessStatus == 13">
                      <template #content>
                        <div>缴款时间：{{ item.endAt }}</div>
                      </template>
                      <i class="iconfont icon-gantanhao gantanhao-yc"></i>
                    </el-tooltip>  </span>
                  <!-- <span v-else>{{0}}</span> -->
                </p>

              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" width="170">
          <template #default="scope">
            <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
              <el-button :disabled="!$buttonStatus('dzsj_sksb_jf')||(orgId == 3576 && ![1,3].includes(scope.row.djqr_tax_status))" @click="handleUpdate(item,scope.row)" size="small" type="text">缴款</el-button>
              <el-button :disabled="item.taskStatus == 0" @click="declareDetail(scope.row,item)" size="small" type="text" style="margin-left: 0;">缴款申报记录</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="taxPaySz"/>
    </div>
    </el-tabs>
  </div>
  <declareDetails ref="declareDetails"></declareDetails>
  <sbImage ref="sbImage"></sbImage>
</template>

<script>
import { companySendTaxList } from "@/api/company"
import { declarationModuleExport } from '@/api/export'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import declareDetails from "./declareDetails"
import { currentAccountPeriod } from '@/utils'
import { sendTask, quitTask } from "@/api/collection"
import selectcity from "@/components/Screening/selectcity";
import selectBussinessKkStatus from "./select/selectBussinessKkStatus.vue"
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import sbImage from "@/components/sbImage";
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION,TAX_CONFIRM_STATUS } from '@/utils/commonData.js'

export default {
  name: "batch-send-tax",
  components:{
    selecttaxtype,
    declareDetails,
    selectcity,
    selectBussinessKkStatus,
    selectAccreditStatus,
    sbImage,
    TagNameCopy,
    TableSortCell
  },
  data(){
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      activeName:'税款申报',
      listQuery: {
        page: 1,
        limit: 20,
        taskName:'tax-kk-',
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        taskStatusSb:[],
        businessStatus:[],
        businessStatusSb:[],
        allStatus: [],
        accountingType: 2,
        taxNames:[],
        taxStatus: 1,
        companyType:''
      },
      total:0,
      list:[],
      ids: [],
      comIds: [],
      pageStatus: true,
      reqNos: [],
      categories: [
        {text: '财务报表', value: 'gs_cwbb'}, 
        {text: '增值税及附加税一般纳税人申报表', value: 'gs_vat'}, 
        {text: '小规模增值税纳税及附加税申报表', value: 'gs_small_vat'},
        {text: '财产和行为税纳税申报表', value: 'gs_deed'},
        {text: '企业所得税申报表', value: 'gs_tax_quarter'},
        {text: '附加税', value: 'gs_fjs'},
        {text: '文化事业建设费申报表', value: 'gs_whsyjsfsbbygz'},
        {text: '残疾人就业保障金申报表', value: 'gs_cbj'},
        {text: '非税收入', value: 'gs_fssr'},
        {text: '水利建设专项收入', value: 'gs_sl'},
        {text: '城市生活垃圾处置费', value: 'gs_ljcl'},
        {text: '其他收入(工会经费)', value: 'gs_qtsr'},
        {text: '现金流量表', value: 'kj_xjllb'},
        {text: '单位社会保险费日常申报(全责)', value: 'gs_sb'},
        {text: '个人所得税(定期定额)', value: 'gs_tax_dqde'},
      ],
      kkStatus: undefined,  //交款状态
      loading:false,
      kkLoading:false,
      cxLoading:false,
      selects:[],
      totalPage:0,
      pageSize:40,
      tableData:[],
      currentPage:1,
      allChecked:false,
      orgId: this.$store.getters["user/user"].orgId,
      confirmOption:TAX_CONFIRM_STATUS,

    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created(){
    this.listQuery.limit = localStorage.getItem('taxPaySz') ? localStorage.getItem('taxPaySz')*1 : 20
    this.getList()
    this.contentStyleObj= this.$getHeight(251)
    this.initBus()
  },
  methods:{
    // 重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery={
        page: 1,
        limit: originLimit,
        taskName:'tax-kk-',
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        taskStatusSb:[],
        businessStatus:[],
        businessStatusSb:[],
        allStatus: [],
        taxNames:[],
        taxStatus: 1,
        districtCode:null,
        userId:null,
        type:null,
        tel:'',
        keepStatus:null,
        beginMoney:'',
        endMoney:'',
        companyType:''
      }
      if(this.activeName == "税款申报") {
        this.listQuery.accountingType = 2
      }if(this.activeName == "零申报") {
        this.listQuery.accountingType = 1
      }
      this.getList()
    },
    cancelTask(){
      this.$confirm('确定撤销扣款任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.cxLoading = true
        quitTask({reqNos:this.reqNos}).then(res => {
          this.cxLoading = false
          if(res.data.msg == 'success') {
            this.$cancelMsg(res.data.data.list)
            this.getList()
          }
        })
      })
    },
    
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.ids.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          res.items.forEach(item => {
            if ([1,2,99].includes(item.taskStatus)) {
              status = true;
            }
          })
        })
        if(status == true) {
          companySendTaxList(this.listQuery).then(res=>{
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.total = res.data.data.total
            this.setScroll()
          })
        }
      });
    },
    getList(){
      this.loading = true
      companySendTaxList(this.listQuery).then(res=>{
        this.loading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
        this.setScroll()
      })
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.reqNos = []
      this.comIds = []
      this.ids = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    handleClick(tab) {
      this.activeName = tab.props.name
      if(tab.props.name == "税款申报") {
        this.listQuery.accountingType = 2
      }if(tab.props.name == "零申报") {
        this.listQuery.accountingType = 1
      }
      this.getList()
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    // 缴费详情
    declareDetail(e,v) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,v.taskName)
    },
    getIds(){
      this.comIds = []
      this.selects.map(item => {
        this.comIds.push(item.comId)
        item.items.map(res => {
          let status = false
          if(res.taskStatus != 3) {
            status = true
          }else {
            status = false
          }
          this.kkStatus = status
        })
      })
      this.ids = []
      this.reqNos = []
      this.selects.map(info=>{
        info.items.map(item=>{
          this.ids.push(item.gsMainId)
          this.reqNos.push(item.reqNo)
        })
      })
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.selects = []
        this.ids = []
        this.reqNos = []
        this.comIds = []
        this.allChecked = false
      }
    },
    //过滤申报状态
    filterGsmainSbStatus(gsMainId){
      let status = false
      this.list.map(info=>{
        info.items.map(item=>{
          if(item.gsMainId == gsMainId  && (item.taskStatusSb == 3 || item.taskStatusSb == 8)){
            status = true
          }
        })
      })
      return status
    },
    //批量缴费
    declaration() {
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      //顶呱呱过滤确税状态
      if(this.orgId == 3576){
        let fail = this.selects.find(v=> ![1,3].includes(v.djqr_tax_status))
        if(fail){
          this.$qzfMessage(`${fail.name}未确认，请先确认！`,1)
          return
        }
      }
      //过滤授权状态
      // let str = ''
      // this.selects.map(v=>{
      //   if(v.district != 'zhejiang' && v.district != 'guangdong' && v.district != 'tianjin' && v.district != 'hubei' && v.loginMethod == '新版登录' && v.yzmType == 1 && v.keepStatus != 1){
      //     str = v.name + '未授权登录，请先授权再发起任务！'
      //     return
      //   }
      // })
      // if(str){
      //   this.$qzfMessage(str,1)
      //   return
      // }
      let status  = true
      this.ids.map(gsMainId=>{
        let s = this.filterGsmainSbStatus(gsMainId)
        if(!s){
          status = false
        }
      })

      if(!status) {
        this.$qzfMessage('请先进行申报',1)
        return
      }
      this.$confirm('确定要扣款吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.kkLoading = true
        let param = {
          taskName: 'tax_kk',
          gsMainIds: this.ids
        }
        sendTask(param).then(res => {
          this.kkLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    },
    //缴费
    handleUpdate(e,row) {
      // if(row.district != 'zhejiang' && row.district != 'guangdong' && row.district != 'tianjin' && row.district != 'hubei' && row.loginMethod == '新版登录' && row.yzmType == 1 && row.keepStatus != 1){
      //   this.$qzfMessage('未授权登录，请先授权再发起任务！',1)
      //   return
      // }
      if(e.taskStatusSb == 3 || e.taskStatusSb == 8) {
        this.$confirm('确定要缴费吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true
          let param = {
            taskName: 'tax_kk',
            gsMainIds: [e.gsMainId]
          }
          sendTask(param).then(res => {
            this.loading = false
            if(res.data.msg == 'success') {
              this.$qzfMessage('任务已发起',3)
              this.getList()
              this.$queueMessage(res.data.data.queue)

            }
          })
        })
      }else {
        this.$qzfMessage('请先进行申报',1)
      }
    },
    open(row, index, bigIndex) {
      let imageAll = []
      let currentIndex = index
      this.list.map((v,i)=>{
        let arr = []
        v.items.map((item,i2)=>{
          let imgList = []
          if(item.imageSb.match(',')){
            let imgUrls = item.imageSb.split(',')
            imgList = imgUrls.filter(v=>{return v})
            imgList.map((e,i3)=>{
              if(e){
                arr.push({
                  img:'https://file.listensoft.net' + e + "?" + Math.random(10),
                  tableName:item.tableName
                })
                if(i2 < index && i == bigIndex && i3>0){
                  currentIndex ++
                }
              }
            })
          }else{
            if(item.imageSb){
              arr.push({
                img:'https://file.listensoft.net' + item.imageSb + "?" + Math.random(10),
                tableName:item.tableName
              })
            }else{
              if(i2<index && i == bigIndex){
                currentIndex --
              }
            }
          }
        })
        let url = {
          name:v.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll,bigIndex,currentIndex)
    },
    openKK(row, index, bigIndex){
      let imageAll = []
      let currentIndex = index
      this.list.map((v,i)=>{
        let arr = []
        v.items.map((item,i2)=>{
          let imgList = []
          if(item.image.match(',')){
            let imgUrls = item.image.split(',')
            imgList = imgUrls.filter(v=>{return v})
            imgList.map((e,i3)=>{
              if(e){
                arr.push({
                  img:'https://file.listensoft.net' + e + "?" + Math.random(10),
                  tableName:item.tableName
                })
                if(i2 < index && i == bigIndex && i3>0){
                  currentIndex ++
                }
              }
            })
          }else{
            if(item.image){
              arr.push({
                img:'https://file.listensoft.net' + item.image + "?" + Math.random(10),
                tableName:item.tableName
              })
            }else{
              if(i2<index && i == bigIndex){
                currentIndex --
              }
            }
          }
        })
        let url = {
          name:v.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll,bigIndex,currentIndex)
    },
     //导出
     daochu(){
      let param = {
        query:this.listQuery,
        comIds: this.comIds
      }
      declarationModuleExport(param).then(res=>{
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    },
    // 表格排序
    sortChange(data) {
      this.listQuery.desc = data.order == "descending" ? 1 : 0;
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
.el-table__column-filter-trigger i {
  color: #fff !important;
  width: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  border-bottom: 1px solid #eee;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.item_icon{
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 4px;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }
}
.timeJe {
  display: flex;
  justify-content: space-around;;
}
.export_btn{
  position: absolute;
  top: 8px;
  right: 18px;
  z-index: 99;
}
</style>
<style lang="scss">
.none-border .cell {
  padding: 0;
}
.el-table__column-filter-trigger i {
  color: #fff !important;
}
.el-table th.el-table__cell>.cell.highlight {
  color: #fff !important;
}
.loudou .el-icon-arrow-down:before{
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e";
  margin-left: 250px;
}
</style>