<template>
  <div class="app">
    <!-- 新增成交线索弹窗 -->
    <el-drawer
      v-model="addDrawer"
      :before-close="handleClose"
      :close-on-click-modal="false"
      size="40%"
    >
      <template #header>
        <div class="drawerHead">
          <div>
            成交线索
          </div>
          <div>
            <el-button
              type="primary"
              @click="saveCule"
              size="small"
              style="margin-right: 5px"
              >保存</el-button
            >
          </div>
        </div>
      </template>
      <template #default>
        <el-form :model="form" :rules="rules" ref="form" label-width="120px" :inline="true">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item name="1">
              <template #title>
                <div class="formTitle">
                  基础信息
                </div>
              </template>
              <el-form-item label="客户名称:" prop="customerName">
                <el-input v-model="form.customerName" size="small" class="w200" disabled />
              </el-form-item>
              <el-form-item label="客户编码:">
                <el-input v-model="form.customerCode" size="small" class="w200" clearable :disabled="dealDisabled || dealType == 'yes'" />
              </el-form-item>
              <el-form-item label="纳税人资格:">
                <el-select
                  v-model="form.taxpayerRight"
                  placeholder="请选择纳税人资格"
                  style="width: 200px;margin: 0;"
                  filterable
                  size="small"
                  :disabled="dealDisabled || dealType == 'yes'" 
                >
                  <el-option
                    v-for="item in taxpayerList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="服务类型:" prop="user2">
                <el-radio-group v-model="form.serviceType" class="w200" :disabled="dealDisabled || dealType == 'yes'" >
                  <el-radio :label="1">代账</el-radio>
                  <el-radio :label="2">增值</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="客户联系人:">
                <el-input v-model="form.customerContacter" size="small" class="w200" clearable :disabled="dealDisabled || dealType == 'yes'" />
              </el-form-item>
              <el-form-item label="联系人手机:">
                <el-input v-model="form.customerContacterMobile" size="small" class="w200" clearable :disabled="dealDisabled || dealType == 'yes'" />
              </el-form-item>
              <el-form-item label="合同总金额:" prop="user2">
                <el-input v-model="form.totalAmount" size="small" class="w200" disabled />
              </el-form-item>
              <el-form-item label="签单人员:">
                <selectuser code="addCustomer" v-model:userId="form.dealUserId" :multiples="false" style="width: 200px;margin: 0;" :disabled="dealDisabled"></selectuser>
              </el-form-item>
              <el-form-item label="签订日期:">
                <el-date-picker
                  style="width:200px;margin: 0;"
                  size="small"
                  v-model="form.dealAt"
                  type="date"
                  clearable
                  value-format="YYYY-MM-DD"
                />
              </el-form-item>
              <el-form-item label="备注:" prop="user2">
                <el-input v-model="form.user2" size="small" class="w200" clearable :disabled="dealDisabled" />
              </el-form-item>
            </el-collapse-item>
            <el-collapse-item name="2" class="ht">
              <template #title>
                <div class="formTitle margin20">
                  合同信息
                </div>
              </template>
              <div>
                <table class="content" cellspacing="0" width="100%">
                  <tr class="top_bg center">
                    <td style="width: 5%;height: 40px;text-align: center;">操作</td>
                    <td style="width: 10%;height: 40px;text-align: center;">业务类型</td>
                    <td style="width: 10%;height: 40px;text-align: center;">开始日期</td>
                    <td style="width: 10%;height: 40px;text-align: center;">结束日期</td>
                    <td style="width: 10%;height: 40px;text-align: center;">收款方式</td>
                    <td style="width: 5%;height: 40px;text-align: center;">收款周期</td>
                    <td style="width: 5%;height: 40px;text-align: center;">赠送月份</td>
                    <td style="width: 5%;height: 40px;text-align: center;">月服务费</td>
                    <td style="width: 5%;height: 40px;text-align: center;">账本费</td>
                    <td style="width: 5%;height: 40px;text-align: center;">费用类型</td>
                    <td style="width: 10%;height: 40px;text-align: center;">合同优惠</td>
                    <td style="width: 10%;height: 40px;text-align: center;">应收金额</td>
                    <td style="width: 5%;height: 40px;text-align: center;">备注</td>
                    <td style="width: 5%;height: 40px;text-align: center;">合同编码</td>
                  </tr>
                  <tr
                    class="centers"
                    v-for="(item, index) in form.contract"
                    :key="index"
                    v-if="form.contract.length > 0"
                  >
                  <td style="width: 5%;">
                      <div style="display: flex;align-items: center;height: 20px;line-height: 20px;justify-content: center;">
                        <el-icon color="#409efc" style="margin-right: 6px;cursor: pointer;" @click="addContract(form.contract)"><Plus /></el-icon> |
                        <el-icon color="#409efc" style="margin-left: 6px;cursor: pointer;" @click="delContract(form.contract, index)"><Minus /></el-icon>
                      </div>
                    </td>
                    <td style="width: 10%">
                      <cascader-common
                        v-model:modelValue="item.businessTypeId"
                        placeholder="请选择业务类型"
                        :options="contractTypeOptions"
                        :casProps="casProps"
                        style="width: 190px"
                      />
                    </td>
                    <td style="width: 10%">
                      <el-date-picker
                        style="width:100px;"
                        size="small"
                        v-model="item.contractStartTime"
                        type="date"
                        placeholder="请选择"
                        clearable
                        value-format="YYYY-MM-DD"
                      />
                    </td>
                    <td style="width: 10%">
                      <el-date-picker
                        style="width:100px;"
                        size="small"
                        v-model="item.contractEndTime"
                        type="date"
                        placeholder="请选择"
                        clearable
                        value-format="YYYY-MM-DD"
                      />
                    </td>
                    <td style="width: 10%">
                       <select-common placeholder="请选择" 
                        v-model:modelValue="item.disposable" 
                        :options="cycleOptions" 
                        style="width: 75px" 
                        @change="changeCycle(item,index)" 
                      />
                    </td>
                    <td style="width: 5%">
                      <el-input
                        size="small"
                        style="width: 70px"
                        v-model="item.paymentCycle"
                        @blur="changePay(item,index)"
                      />
                    </td>
                    <td style="width: 5%">
                      <el-input
                        size="small"
                        style="width: 70px"
                        v-model="item.giveNum"
                        @blur="changeGive(item,index)"
                      />
                    </td>
                    <td style="width: 5%">
                      <el-input
                        size="small"
                        style="width: 70px"
                        v-model="item.serviceCharge"
                        @blur="changeService(item,index)"
                      />
                    </td>
                    <td style="width: 5%">
                      <el-input
                        size="small"
                        style="width: 70px"
                        v-model="item.accountBookFee"
                        @blur="changeAccount(item,index)"
                      />
                    </td>
                    <td style="width: 5%">
                      <cascader-common
                        v-model:modelValue="item.costTypeId"
                        placeholder="请选择费用类型"
                        :options="paymentTypeOptions"
                        :casProps="casProps"
                      />
                    </td>
                    <td style="width: 10%">
                      <el-input
                        size="small"
                        style="width: 70px"
                        v-model="item.contractDiscount"
                        @blur="changeContract(item,index)"
                      />
                    </td>
                    <td style="width: 110%">
                      <el-input
                        size="small"
                        style="width: 70px"
                        v-model="item.receivedAmount"
                      />
                    </td>
                    <td style="width: 5%">
                      <el-input
                        v-model="item.remark"
                        size="small"
                        style="width: 100px"
                        placeholder="请输入备注"
                      />
                    </td>
                    <td style="width: 5%">
                      <el-input
                        size="small"
                        style="width: 70px"
                        v-model="item.contractNum"
                      />
                    </td>
                  </tr>
                  <tr v-else>
                    <td style="text-align: center" colspan="24">
                      <div>
                        <span style="font-size: 12px;color: #AAAAAA;">暂无相关数据，点击下方按钮快速新增</span>
                        <div
                          style="
                            color: var(--themeColor, #17a2b8);
                            cursor: pointer;
                            z-index: 9999;
                            position: relative;
                          "
                          @click="addContract(form.contract)"
                        >
                          +添加合同信息
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </el-collapse-item>
            <el-collapse-item name="3">
              <template #title>
                <div class="formTitle margin20">
                  合同附件
                </div>
              </template>
              <el-form-item label="代账合同:" class="disIcon" v-if="form.contractType == 0">
                <imgupload :fileListTemp="form.dzAttachment" @updatet="updateFileList" @del="delFileList" style="width: 600px;"></imgupload>
              </el-form-item>
              <el-form-item label="增值合同:" prop="user2" v-else>
                <imgupload :fileListTemp="form.zzAttachment" @updatet="updateFileList2" @del="delFileList2" style="width: 600px;"></imgupload>
              </el-form-item>
            </el-collapse-item>
            <el-collapse-item name="4">
              <template #title>
                <div class="formTitle margin20">
                  客户资料
                </div>
              </template>
              <div>
                <table class="content" cellspacing="0" width="100%">
                  <tr class="top_bg center">
                    <td style="width: 10%;height: 40px;text-align: center;">资料名称</td>
                    <td style="width: 14%;height: 40px;text-align: center;">资料类型</td>
                    <td style="width: 11%;height: 40px;text-align: center;">资料件数</td>
                    <td style="width: 21%;height: 40px;text-align: center;">开始期间</td>
                    <td style="width: 21%;height: 40px;text-align: center;">结束期间</td>
                    <td style="width: 12%;height: 40px;text-align: center;">备注</td>
                    <td style="width: 11%;height: 40px;text-align: center;">操作</td>
                  </tr>
                  <tr
                    class="centers"
                    v-for="(item, index) in form.information"
                    :key="index"
                    v-if="form.information.length > 0"
                  >
                    <td style="width: 10%">
                      <el-input
                        v-model="item.contractAmount"
                        size="small"
                        style="width: 110px"
                        placeholder="请选择资料"
                        readonly
                      >
                        <template #append>
                          <el-icon @click="selectInfo"><Search /></el-icon>
                        </template>
                      </el-input>
                    </td>
                    <td style="width: 14%">
                      <el-select
                        v-model="item.infoType"
                        placeholder="请选择资料类型"
                        size="small"
                        style="width: 75px"
                        @change="changeDisable(item)"
                      >
                        <el-option
                          v-for="item in informationTypeList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </td>
                    <td style="width: 11%">
                      <el-input
                        size="small"
                        style="width: 70px"
                        v-model="item.quantity"
                      />
                    </td>
                    <td style="width: 18%">
                      <el-date-picker
                        type="month"
                        placeholder="请选择开始时间"
                        size="small"
                        style="width: 100%"
                        v-model="item.startAt"
                      />
                    </td>
                    <td style="width: 18%">
                      <el-date-picker
                        type="month"
                        placeholder="请选择结束时间"
                        size="small"
                        style="width: 100%"
                        v-model="item.endAt"
                      />
                    </td>
                    <td style="width: 16%">
                      <el-input
                        v-model="item.remark"
                        size="small"
                        style="width: 100%"
                        placeholder="请输入备注"
                      />
                    </td>
                    <td style="width: 11%;">
                      <div style="display: flex;align-items: center;height: 20px;line-height: 20px;justify-content: center;">
                        <el-icon color="#409efc" style="margin-right: 6px;cursor: pointer;" @click="addCustomer(form.information)"><Plus /></el-icon> |
                        <el-icon color="#409efc" style="margin-left: 6px;cursor: pointer;" @click="delCustomer(form.information, index)"><Minus /></el-icon>
                      </div>
                    </td>
                  </tr>
                  <tr v-else>
                    <td style="text-align: center" colspan="7">
                      <span style="font-size: 12px;color: #AAAAAA;">暂无相关数据，点击下方按钮快速新增</span>
                      <div
                        style="
                          color: var(--themeColor, #17a2b8);
                          cursor: pointer;
                          z-index: 9999;
                          position: relative;
                        "
                        @click="addCustomer(form.information)"
                      >
                        +添加客户资料
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form>
      </template>
    </el-drawer>
  </div>

  <!-- 选择弹窗 -->
  <selectinformation ref="selectinformation" @success="getInfo"></selectinformation>
</template>

<script>
import imgupload from "./imgUpload.vue"//成交线索抽屉
import { INFORMATION,TRAXPAYER,COLLECTION_CYCLE,INFORMATION_TYPE } from "@/utils/commonData";
import selectinformation from "./selectInformation.vue"
import selectBusiness from "../../../crm/components/selectBusiness.vue";
import { categoryListNew, } from "@/api/crm";
import { getCurrentDateFormatted } from '@/utils'
import { contractTypeList,deal } from "@/api/newCrm.js";

export default {
  name: '',
  components: {
    imgupload,
    selectinformation,
    selectBusiness
  },
  props: {},
  data () {
    return {
      cycleOptions: COLLECTION_CYCLE,
      selectedValues: [], // 存储选中的值 
      addDrawer: false,//抽屉
      activeNames: ['1','2','3','4'],
      form: {
        information:[],
        contract:[],
        dzAttachment:[],
        zzAttachment:[],
      },
      taxpayerList: TRAXPAYER,//纳税人资格
      dealDisabled: false,//查看所有选项禁掉
      dealType: '',//已成交部分选项禁掉
      dataType: INFORMATION,
      categoryList: [],
      contractTypeOptions:[],//业务类型
      paymentTypeOptions:[],//费用类型
      casProps:{
        expandTrigger: "hover",
        label: "name",
        value: "id",
      },
      cycleOptionsTemp:[
        { label: '一次性', value: 1 },
        { label: '月', value: 1 },
        { label: '季', value: 3 },
        { label: '半年', value: 6 },
        { label: '年', value: 12 },
        { label: '2年', value: 24 },
      ],
      informationTypeList: INFORMATION_TYPE
    }
  },
  computed: {},
  watch: {
    'form.contract': {
      handler(newVal, oldVal) {
        console.log(newVal)
        // 表单数据发生变化时，需要做的事情
        this.form.totalAmount = 0
        newVal.forEach(item => {
          // 累加到 all
          this.form.totalAmount += item.receivedAmount;
          item.giveNum = item.giveNum*1
          item.accountBookFee = item.accountBookFee*1
          item.serviceCharge = item.serviceCharge*1
          item.contractDiscount = item.contractDiscount*1
        });
      },
      deep: true
    }
  },
  created () {
    this.getBussList()
    this.getPayList()
    // this.defaultExpandedKeys = this.dataType.map(item => item.value);  
  },
  mounted () {},
  methods: {
    init(row){
      this.addDrawer = true;
      this.form = {...row}
      this.form.information = [],
      this.form.contract = []
      this.form.id = null
      this.form.clueId = row.id
      this.form.dealUserId = row.followUserId
      this.form.contractType = row.serviceType
      this.form.dealAt = getCurrentDateFormatted()
      this.form.totalAmount = 0
    },
    getBussList(){
      contractTypeList({ contractType: '2' }).then((res) => {
        this.contractTypeOptions = res.data.data.list;
        console.log(res);
      });
    },
    getPayList(){
      contractTypeList({ contractType: '3' }).then((res) => {
        this.paymentTypeOptions = res.data.data.list;
        console.log(res);
      });
    },
    handleChange(val) {
      // 如果尝试关闭第一个 item，则重新打开它  
      if (!val.includes('1')) {  
        this.activeNames.push('1')  
      } else {  
        console.log(val);
        // 允许其他 item 的状态改变  
        this.activeNames = val;  
      }  
    },
    saveCule(){
      // 保存成交线索
      console.log(this.form);
      this.$confirm('确定保存吗？保存后会自动生成客户档案、代账合同', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deal(this.form).then((res) => {
          console.log(res);
          if (res.data.msg == "success") {
            this.$message.success('保存成功');
            this.addDrawer = false
            this.$emit('success')
          }
        });
      })
    },
    updateFileList(url) {
      this.form.dzAttachment = url.toString()
      console.log(this.form);
      // 处理上传成功后的逻辑，例如更新表单字段
    },
    delFileList(url){
      this.form.dzAttachment = url.toString()
      console.log(this.form);
    },
    updateFileList2(url) {
      this.form.zzAttachment = url.toString()
      console.log(this.form);
      // 处理上传成功后的逻辑，例如更新表单字段
    },
    delFileList2(url){
      this.form.zzAttachment = url.toString()
      console.log(this.form);
    },
    //添加客户资料
    addCustomer(item) {
      item.push({
        categoryNameId: null,
        disposable: "1",
        contractStartTime: "",
        contractEndTime: "",
        contractAmount: "",
      });
    },
    //删除客户资料
    delCustomer(item, index) {
      // if (item.length <= 1) {
      //   this.$message.error("仅剩一项禁止删除");
      //   return;
      // }
      item.splice(index, 1);
    },

    //添加合同信息
    addContract(item) {
      item.push({
        businessTypeId:null,
        contractStartTime:'',
        contractEndTime: '',
        disposable: '',
        paymentCycle: 0,
        giveNum: 0,
        serviceCharge: 0,
        accountBookFee: 0,
        costTypeId: null,
        contractDiscount: 0,
        receivedAmount: 0,
        remark:'',
        contractNum:'',
      });
    },
    //删除合同信息
    delContract(item, index) {
      // if (item.length <= 1) {
      //   this.$message.error("仅剩一项禁止删除");
      //   return;
      // }
      item.splice(index, 1);
    },
    // 收款方式
    changeCycle(val,i){
      console.log(val,i);
      if(!val.contractStartTime){
        this.form.contract[i].contractStartTime = getCurrentDateFormatted()
      }else{
        this.getEndTime(val.contractStartTime,val.disposable,i)
      }
    },

    //获取结束日期
    getEndTime(time,val,i){
      let currentDate = new Date(time)
      if(val == '月'){
        let lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        this.form.contract[i].contractEndTime = lastDayOfMonth.toISOString().split('T')[0];
        this.form.contract[i].paymentCycle = 1
      }else if(val == '季'){
        let oneQuarterLater = new Date(currentDate);
        oneQuarterLater.setMonth(currentDate.getMonth() + 3);
        this.form.contract[i].contractEndTime = oneQuarterLater.toISOString().split('T')[0];
      }else if(val == '半年'){
        let sixMonthsLater = new Date(currentDate);
        sixMonthsLater.setMonth(currentDate.getMonth() + 6);
        this.form.contract[i].contractEndTime = sixMonthsLater.toISOString().split('T')[0];
      }else if(val == '年'){
        let oneYearLater = new Date(currentDate);
        oneYearLater.setFullYear(currentDate.getFullYear() + 1);
        this.form.contract[i].contractEndTime = oneYearLater.toISOString().split('T')[0];
      }else if(val == '2年'){
        let twoYearsLater = new Date(currentDate);
        twoYearsLater.setFullYear(currentDate.getFullYear() + 2);
        this.form.contract[i].contractEndTime = twoYearsLater.toISOString().split('T')[0];
      }
    },

    // 收款周期
    changePay(val,i){
      this.form.contract[i].receivedAmount = val.paymentCycle*1 * val.serviceCharge*1 + val.accountBookFee*1 - val.contractDiscount*1;
    },
    // 赠送月份
    changeGive(val,i){
      console.log(val,i);
    },
    // 月服务费
    changeService(val,i){
      this.form.contract[i].receivedAmount = val.paymentCycle*1 * val.serviceCharge*1 + val.accountBookFee*1 - val.contractDiscount*1;
    },
    // 账本费
    changeAccount(val,i){
      this.form.contract[i].receivedAmount = val.paymentCycle*1 * val.serviceCharge*1 + val.accountBookFee*1 - val.contractDiscount*1;
    },
    // 合同优惠
    changeContract(val,i){
      this.form.contract[i].receivedAmount = val.paymentCycle*1 * val.serviceCharge*1 + val.accountBookFee*1 - val.contractDiscount*1;
    },
    // 选择资料名称
    selectInfo(){
      this.$refs.selectinformation.init()
    },
    // 资料获取
    getInfo(data,ids){
      console.log(data,ids);
      this.form.information = this.form.information.filter(i => i.contractAmount !== '');
      data.forEach(item => {
        this.form.information.push({
          contractAmount: item.label,
          templateID: item.id,
          quantity: null,
          startAt: "",
          endAt: "",
          remark: '',
          infoType: 1,
        })
      });
    },
    // 业务类型相关操作
    getOptions() {
      // 初始化 options
      this.options = [];

      // 确保 categoryList 和 form.server 都存在
      if (!this.categoryList || !this.form.server) return;

      // 创建一个映射表用于快速查找
      const serverMap = new Map();
      this.form.server.forEach((item) => {
        if (!item.id) {
          // 设置 disposable 属性
          item.disposable = item.categoryNameId === 5 ? "0" : "1";
        }
        // 使用 Map 存储 id 对应的 item
        serverMap.set(item.categoryNameId, item);
      });

      // 遍历 categoryList 并添加符合条件的子项
      this.categoryList.forEach((v) => {
        v.children.forEach((k) => {
          if (serverMap.has(k.id)) {
            this.options.push(k);
          }
        });
      });

      // 去重
      this.options = [...new Set(this.options.map((option) => option.id))].map(
        (id) => {
          return this.options.find((option) => option.id === id);
        }
      );
    },

    getCategory() {
      categoryListNew({}).then((res) => {
        if (res.data.msg == "success") {
          this.categoryList = res.data.data.list ? res.data.data.list : [];
          this.getOptions();
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
.formTitle{
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 14px;
}
.w200{
  width: 200px;
}
.drawerHead{
  display: flex;
  justify-content: space-between;
}
.up{
  margin: 2px 0 0 3px;
  transform: rotate(-90deg);  
  cursor: pointer;
  z-index: 9999;
}
.down{
  margin: 2px 0 0 3px;
  transform: rotate(-90deg); 
  cursor: pointer; 
  z-index: 9999;
}
:deep(.el-collapse){
  border: none;
}
:deep(.el-collapse-item__arrow){
  margin: 0 8px 0 5px!important;
}
:deep(.el-collapse-item:first-of-type .el-collapse-item__header .el-collapse-item__arrow) {  
  display: none;  
}
.margin20{
  margin-top: 20px;
}
.content {
  width: 100%;
  overflow-x: auto; /* 启用水平滚动条 */  
  white-space: nowrap; /* 防止内容换行 */ 
  min-height: 72px;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  span {
    line-height: 28px;
    font-size: 13px;
  }
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    height: 32px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}
.centers {
  text-align: center;
  height: 30px;
  width: 100%;
}
:deep(.el-input-group__append, .el-input-group__prepend){
  padding: 0 6px;
  cursor: pointer;
}
.ht :deep(.el-collapse-item__wrap){
  overflow-x: auto!important;
}
.scroll-container{
  overflow-x: auto; /* 允许水平滚动 */  
  max-width: 100%; 
  position: relative;
}
.no-data-container {  
  position: fixed; /* 固定在视口 */  
  top: 50%; /* 垂直居中 */  
  left: 50%; /* 水平居中（需要配合 transform） */  
  transform: translate(-50%, -50%); /* 调整位置以正确居中 */  
  text-align: center; /* 文本居中 */  
  width: 100%; /* 或其他适当的宽度，但这里使用 100% 会导致水平居中失效，需要调整 */  
}  
</style>
